import * as React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Switch, Align } from "../../UI";
import { PrivacyContent } from "./PrivacyContent";
import { PrivacyContainer } from "./PrivacyPolicy.styled";
const arrowBack = require("../../../assets/images/arrow-back.svg");

const PrivacyGoBack = styled.img`
  cursor: pointer;
`;

export const PrivacyPolicy = () => {
    return (
        <PrivacyContainer>
            <Align direction="column" align="center" justify="center">
                <Align direction="row" align="center" justify="space-between">
                    <PrivacyGoBack
                        src={arrowBack}
                        onClick={() => {
                            navigate("/");
                        }}
                        alt="go back"
                    />
                    <Switch />
                </ Align>
            </ Align>
            <PrivacyContent />
        </PrivacyContainer>
    );
};