import * as React from "react";
import { defaultTheme } from "../../../styles/theme";
import { Text, Align, Title, Divider } from "../../UI";
import { useIntl } from "gatsby-plugin-react-intl";
import { PrivacyContentContainer } from "./PrivacyPolicy.styled";

const companyInformations = [
  "Geeknauts Sp. z o.o.",
  "ul. Ulubiona 34",
  "Modlnica 32-085",
  "NIP: 5130210402",
  "REGON: 121307472",
  "KRS: 0000362389"
];

const govInformations = [
  "Urząd Ochrony Danych Osobowych",
  "ul. Stawki 2, 00-193 Warszawa",
  " Strona internetowa:",
  "https://www.uodo.gov.pl/"
];

export const PrivacyContent = () => {
  const intl = useIntl();
  if (intl.locale === "pl") {
    return (
      <PrivacyContentContainer>
        <Title size="huge" color={defaultTheme.colors.primary} align="center">
          Polityka Prywatności
        </Title>

        <Text size="small" color={defaultTheme.colors.primary} spacing="0">
          <p>
            Niniejszy dokument opisuje zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies i
            innych technologii śledzących wykorzystywanych na stronie{" "}
            <a href="https://www.geeknauts.com">www.geeknauts.com</a>
          </p>
          <p>
            Szanujemy prywatność Użytkowników korzystających ze strony internetowej i zależy nam, by każdy odwiedzający
            nasze witryny wiedział jak może chronić swoją prywatność. Dlatego zachęcamy do zapoznania się z niniejszym
            dokumentem.
          </p>
          <p>
            Niniejsza Polityka Prywatności została przygotowana w oparciu i w celu wypełnienie obowiązków prawnych
            określonych w Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych oraz w sprawie swobodnego
            przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (
            <strong>„Rozporządzenie RODO”</strong>).
            <br></br>
            <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
              Kliknij tutaj, aby zapoznać się z pełną wersją rozporządzenia RODO w jednym z języków UE .
            </a>
          </p>
          <p>
            Korzystając <strong>ze strony internetowej</strong> akceptujesz zasady Polityki Prywatności.
          </p>
        </Text>
        <Align direction="column" align="left" justify="left">
          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Administrator danych osobowych
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Administratorem danych osobowych <strong>strony internetowej</strong> jest:
            </p>
            <p>
              {companyInformations.map(info => (
                <Align direction="column" align="left" justify="none">
                  {info}
                </Align>
              ))}
            </p>
            <p>
              Możesz uzyskać więcej informacji kontaktując się z nami przez e-mail: privacy@geeknauts.com lub
              tradycyjnie na powyższy adres siedziby Grupy Geeknauts Sp. z o.o.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Bezpieczeństwo danych
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Administrator Portali gwarantuje Użytkownikowi poufność wszelkich przekazanych mu danych osobowych.
              Zapewnia podjęcie wszelkich środków bezpieczeństwa i ochrony danych osobowych wymaganych przez przepisy o
              ochronie danych osobowych. Dane osobowe są gromadzone z należytą starannością i odpowiednio chronione
              przed dostępem do nich przez osoby do tego nieupoważnione.
            </p>
            <p>Serwery na których przechowujemy dane osobowe zlokalizowane są na terenie Unii Europejskiej.</p>
            <p>
              Stosujemy zabezpieczenia techniczne i kryptograficzne chroniące przetwarzane dane przed niepowołanym
              dostępem, zmianą lub usunięciem.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Jakie informacje zbieramy?
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Naszym celem nie jest zbieranie informacji o użytkownikach. Wszystkie zbierane dane traktujemy anonimowo i
              nie podejmujemy żadnych starań w celu identyfikacji użytkownika. Dane które zbieramy pochodzą od
              użytkownika z formularza, tzw. ciasteczek (cookies) oraz tzw. numerów IP.
            </p>
            <ul>
              <li>
                <strong>Adres IP</strong>
              </li>
              <ol>
                Adres IP to niepowtarzalny numer każdego komputera (w tym urządzeń mobilnych) podłączonych do internetu.
                Na jego podstawie można np. zidentyfikować kraj lub region z którego dany użytkownik łączy się z siecią.
                Nie mamy dostępu do danych łączących adres IP z innymi danymi identyfikującymi osobę.
              </ol>
              <li>
                <strong>Ciasteczka (cookies), pamięć przeglądarki i pamięć podręczna urządzenia</strong>
              </li>
              <ol>
                Pliki cookies (tzw. ciasteczka) to niewielkie pliki tekstowe wysyłane przez odwiedzane przez Użytkownika
                serwisy internetowe do komputera/smartfonu/itp. Użytkownika, na którym są zapisywane. Używamy cookies,
                aby:
                <ul>
                  <li>
                    ułatwić użytkownikom korzystanie ze <strong>strony internetowej</strong>- pliki cookies rozpoznają
                    Twoje urządzenie i odpowiednio wyświetlają stronę internetową,
                  </li>
                  <li>
                    tworzyć anonimowe statystyki strony internetowej - dzięki temu możemy ocenić poziom zainteresowania
                    treściami zawartymi
                    <strong>na naszej stronie internetowej</strong> oraz możemy zaprezentować skuteczność naszych
                    działań naszym reklamodawcom.
                  </li>
                </ul>
                Najczęściej przeglądarki internetowe domyślnie zapisują pliki cookies, ale możesz autoryzować,
                zablokować lub usunąć pliki cookie zainstalowane na swoim komputerze ustawiając opcje swojej
                przeglądarki. W przypadku najpopularniejszych przeglądarek możesz skorzystać ze wskazówek dostępnych pod
                poniższymi linkami:
                <ul>
                  <strong>
                    <li>
                      <a href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop">
                        Mozilla Firefox
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=pl">Google Chrome</a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">Apple Safari</a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=pl&co=GENIE.Platform=Desktop">
                        Microsoft Edge
                      </a>
                    </li>
                  </strong>
                </ul>
                W przypadku stosowania urządzeń mobilnych, ze względu na dużą różnorodność i dowolność wdrożeń przez
                producentów, ustawień dotyczących cookies należy szukać w instrukcjach obsługi wyszukiwarki w danym
                urządzeniu. Informujemy, że wyłączenie pliku cookie może utrudnić poruszanie się po{" "}
                <strong>stronie internetowej</strong>.
              </ol>

              <li>
                <strong>Tagi pikselowe</strong>
              </li>
              <ol>
                Stosujemy tagi pikselowe, czyli elementy umieszczane w kodzie strony internetowej, które umożliwiają
                monitorowanie aktywności takiej jak wyświetlenie danej strony lub jej części.
              </ol>

              <li>
                <strong>Logi</strong>
              </li>
              <ol>
                Logi czyli informacje automatycznie zapisywane przez serwery, takie jak żądanie strony wysyłane przez
                użytkownika, datę i godzinę żądania, dane urządzenia (np. model sprzętu), typ przeglądarki, język
                przeglądarki, typ systemu operacyjnego, adres IP oraz pliki cookies. Logi zapisywane i przechowywane są
                na serwerach. Dane zapisane w logach serwerów nie są kojarzone z konkretnymi osobami korzystającymi ze
                Strony i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika. Logi serwera
                stanowią wyłącznie materiał pomocniczy służący do administrowania Stroną, a ich zawartość nie jest
                ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.
              </ol>

              <li>
                <strong>Stosowanie profilowania</strong>
              </li>
              <ol>
                Nie stosujemy profilowania, co oznacza, że Twoje dane nie są przetwarzane w zautomatyzowany sposób w
                celu prognozowania zachowania i osobistych preferencji użytkowników.
              </ol>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Cele i podstawa na jakiej przetwarzamy Twoje dane osobowe
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Odwiedzając naszą <strong>stronę internetową</strong> gromadzimy Twoje dane, jak adres IP i dane związane
              z plikami cookies i innymi technologiami. Przetwarzamy je w następujących celach, zgodnie z art. 6 ust. 1
              f) RODO (nasz uzasadniony interes):
            </p>
            <ul>
              <li> statystycznych i analitycznych, </li>
              <li>
                {" "}
                zapewnieniu bezpieczeństwa i niezawodności <strong>Strony Internetowej</strong>,
              </li>
              <li> marketingowym, tj. prezentowania reklam użytkownikowi,</li>
              <li>
                {" "}
                obrony przed roszczeniami, np. z powodu niezgodnych z prawem treści publikowanych przez Użytkownika.
              </li>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} bold={true}>
            Komu przekazujemy Twoje dane
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>Przetwarzanie Twoich danych osobowych powierzamy:</p>
            <ul>
              <li>
                {" "}
                Firmie Google, poprzez narzędzie analityczne Google Analytics. To narzędzie działa w oparciu o pliki
                cookie, jednak nie udostępnia danych umożliwiających identyfikację osób. Ze szczegółami polityki
                prywatności Google Analytics można zapoznać się{" "}
                <a href="https://support.google.com/analytics/answer/6004245?p=privpol_data&hl=pl&visit_id=637792329821034710-74722126&rd=1">
                  tutaj
                </a>
              </li>
              <li>Firmie OVH SAS utrzymującej serwery na których działają strony internetowe Geeknauts</li>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Uprawnienia Użytkownika
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Masz prawo dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia, jak i również
              prawo do ograniczenia ich przetwarzania/ prawo do cofnięcia zgody, prawo do przenoszenia danych, prawo do
              wniesienia sprzeciwu wobec przetwarzania Twoich danych osobowych. Przysługuje Ci prawo wniesienia skargi
              do organu nadzorczego, jeśli Twoim zdaniem, przetwarzanie Twoich danych osobowych – narusza przepisy
              unijnego rozporządzenia RODO.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Okres przechowywania danych
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Przechowujemy dane osobowe przez okres niezbędny do realizacji celów, o których Użytkownik został
              poinformowany.
            </p>
            <p>
              Okres przechowywania danych osobowych jest ustalony zgodnie z obowiązującymi przepisami prawa. Masz prawo
              do uzyskania od nas informacji jaki jest prawdopodobny czas przechowywania danych osobowych.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Organ nadzorczy
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              W razie wątpliwości lub zastrzeżeń co do sposobu, w jaki przetwarzamy Twoje dane, zachęcamy do kontaktu w
              pierwszej kolejności z nami. W związku z przetwarzaniem danych osobowych przez Administratora przysługuje
              Ci prawo do wniesienia skargi do organu nadzorczego:
            </p>
            <p>
              {govInformations.map(info => (
                <Align direction="column" align="left" justify="none">
                  {info}
                </Align>
              ))}
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Zmiany w polityce prywatności
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Administrator <strong>Strony Internetowej</strong> zastrzega sobie prawo do aktualizacji niniejszej
              Polityki prywatności w zakresie prawa. Aktualna wersja Polityki prywatności jest dostępna na stronie{" "}
              <a href="https://www.geeknauts.com/pl/privacy-policy">www.geeknauts.com/pl/privacy-policy</a>
            </p>
          </Text>
        </Align>
        <Align direction="column" align="center" justify="none">
          <Divider />
        </Align>
      </PrivacyContentContainer>
    );
  } else {
    return (
      <PrivacyContentContainer>
        <Title size="huge" color={defaultTheme.colors.primary} align="center">
          Privacy Policy
        </Title>

        <Text size="small" color={defaultTheme.colors.primary} spacing="0">
          <p>
            This document describes the rules for the processing of personal data and the use of cookies and other
            tracking technologies used on the website
            <a href="https://www.geeknauts.com"> www.geeknauts.com</a>
          </p>
          <p>
            We respect the privacy of Portal Users and we want every visitor to our site to know how to protect their
            privacy. Therefore, we encourage you to read this document.
          </p>
          <p>
            This Privacy Policy has been prepared based on and to fulfill legal obligations specified in Regulation (EU)
            2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons
            with regard to the processing of personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation) (<strong>„GDPR Regulation”</strong>).
            <br></br>
            <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
              Click here for the full version of the GDPR Regulation in one of the EU languages.
            </a>
          </p>
          <p>
            {" "}
            By using <strong>website</strong>, you accept the Privacy Policy.
          </p>
        </Text>

        <Align direction="column" align="left" justify="left">
          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Administrator of personal data
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              The administrator of <strong>website</strong> personal data is:
            </p>
            <p>
              {companyInformations.map(info => (
                <Align direction="column" align="left" justify="none">
                  {info}
                </Align>
              ))}
            </p>
            <p>
              You can get more information by contacting us by e-mail: privacy@geeknauts.com or traditionally at the
              address of the headquarters of Grupa Geeknauts Sp. z o.o.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Data safety
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              The Portal Administrator guarantees to the User the confidentiality of all personal data provided to him.
              Ensures taking all security measures and protecting personal data required by the provisions on the
              protection of personal data. Personal data is collected with due diligence and properly protected against
              access by unauthorized persons.
            </p>
            <p>Servers on which we store personal data are located in the European Union.</p>
            <p>
              We use technical and cryptographic safeguards to protect processed data against unauthorized access,
              change or deletion.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            What information we collect?
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              Our goal is not to collect information about users. We treat all collected data anonymously and we do not
              make any efforts to identify the user. The data we collect comes from the user (e.g. from comments), the
              so-called cookies (cookies) and so-called IP numbers.
            </p>
            <ul>
              <li>
                <strong>IP Adress</strong>
              </li>
              <ol>
                An IP address is a unique number for each computer (including mobile devices) connected to the Internet.
                On its basis, you can, for example, identify the country or region from which a given user connects to
                the network. We do not have access to data linking the IP address with other person identifying
                information.
              </ol>
              <li>
                <strong>Cookies, browser memory and device cache</strong>
              </li>
              <ol>
                Cookies are small text files sent by the website visited by the User to a computer / smartphone / etc.
                The user on which they are saved. We use cookies to:
                <ul>
                  <li>
                    make it easier for users to use <strong>website</strong> - cookies recognize your device and
                    properly display the website,
                  </li>
                  <li>
                    create anonymous <strong>website</strong> statistics - thanks to this we can assess the level of
                    interest in content on our Portal and we can present the effectiveness of our activities to our
                    advertisers.
                  </li>
                </ul>
                Usually, browsers save cookies by default, but you can authorize, block or delete cookies installed on
                your computer by setting your browser options. In the case of the most popular browsers, you can use the
                tips available at the following links:
                <ul>
                  <strong>
                    <li>
                      <a href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop">
                        Mozilla Firefox
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=pl">Google Chrome</a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">Apple Safari</a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=pl&co=GENIE.Platform=Desktop">
                        Microsoft Edge
                      </a>
                    </li>
                  </strong>
                </ul>
                In the case of use of mobile devices, due to the large variety and freedom of implementation by
                manufacturers, the settings for cookies should be searched in the browser's manual for the device.
                Please be advised that disabling a cookie may make it difficult to navigate <strong>website</strong>.
              </ol>

              <li>
                <strong>Pixel Tags</strong>
              </li>
              <ol>
                We use pixel tags, ie elements placed in the website's code, which allow you to monitor activity such as
                displaying a given page or part of it.
              </ol>

              <li>
                <strong>Comments</strong>
              </li>
              <ol>
                {" "}
                By adding a comment to the article, you voluntarily submit your data to the Administrator in the form of
                a signature and commentary. The system also saves the number of your IP number used by your device. Your
                Signature and the content of the comment will be visible to the public, unless it is removed by the
                Administrator. Your IP address is not shown.
              </ol>

              <li>
                <strong>Logs</strong>
              </li>
              <ol>
                Logs or information automatically automatically saved by servers, such as the page request sent by the
                user, the date and time of the request, device data (e.g., hardware model), browser type, browser
                language, operating system type, IP address and cookies. Logs are saved and stored on servers. Data
                stored in server logs are not associated with specific people using the Website and are not used by the
                Administrator to identify the User. The server logs are only auxiliary material used to administer the
                Site, and their content is not disclosed to anyone except those authorized to administer the server.
              </ol>

              <li>
                <strong>Applying profiling</strong>
              </li>
              <ol>
                We do not use profiling, which means that your data is not processed in an automated way to predict the
                behavior and personal preferences of users.
              </ol>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Goals and the basis on which we process your personal data
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              By visiting our <strong>website</strong>, we collect your data, such as the IP address and data related to
              cookies and other technologies. We process them for the following purposes, in accordance with art. 6 par.
              1 f) GDPR (our legitimate interest):
            </p>
            <ul>
              <li>statistical and analytical,</li>
              <li>ensuring security and reliability of website,</li>
              <li>marketing, i.e. presenting ads to the user,</li>
              <li>defenses against claims, e.g. due to illegal content published by the User.</li>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} bold={true}>
            Who we transfer your data to
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>We entrust the processing of your personal data to:</p>
            <ul>
              <li>
                The company Geeknauts Sp. z o.o. providing full IT support for website servers and applications.
                According to the agreement concluded for these services, personal data were entrusted to it. They are
                processed in accordance with our Privacy Policy;
              </li>
              <li>
                {" "}
                Google, through the Google Analytics analytical tool. This tool works on the basis of cookies, but does
                not provide personally identifiable information. You can read the details of the Google Analytics
                privacy policy{" "}
                <a href="https://support.google.com/analytics/answer/6004245?p=privpol_data&hl=pl&visit_id=637792329821034710-74722126&rd=1">
                  here
                </a>
              </li>
              <li>OVH SAS which maintains servers on which Portali applications operate.</li>
            </ul>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            User Rights
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              You have the right to access your personal data, the right to rectify it, delete it, as well as the right
              to limit their processing / the right to withdraw consent, the right to transfer data, the right to object
              to the processing of your personal data. You have the right to lodge a complaint with the supervisory
              body, if in your opinion, the processing of your personal data iolates the provisions of the EU GDPR
              Regulation.
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            The period of data storage
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              {" "}
              We store personal data for the period necessary to achieve the goals for which you have been informed. The
              period of storage of personal data is determined in accordance with applicable law. You have the right to
              obtain from us information on the probable duration of personal data storage.
            </p>{" "}
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Supervisory authority
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              In case of doubts or objections regarding the way in which we process your data, we encourage you to
              contact us first. In connection with the processing of personal data by the Administrator, you have the
              right to lodge a complaint to the supervisory authority:
            </p>
            <p>
              {govInformations.map(info => (
                <Align direction="column" align="left" justify="none">
                  {info}
                </Align>
              ))}
            </p>
          </Text>

          <Title size="small" color={defaultTheme.colors.primary} align="left" bold={true}>
            Changes to the privacy policy
          </Title>
          <Text size="small" color={defaultTheme.colors.primary} spacing="0">
            <p>
              The Portal Administrator reserves the right to update this Privacy Policy in the field of law. The current
              version of the Privacy Policy is available on the website{" "}
              <a href="https://www.geeknauts.com/pl/privacy-policy">www.geeknauts.com/pl/privacy-policy</a>
            </p>
          </Text>
        </Align>
        <Align direction="column" align="center" justify="none">
          <Divider />
        </Align>
      </PrivacyContentContainer>
    );
  }
};
