import styled from "styled-components";
import { defaultTheme } from "../../../styles/theme";


export const PrivacyContainer = styled.div`
  box-sizing: border-box;
  display: block;
  min-height: 100vh;
  width: 100%;
  padding: ${defaultTheme.spacing.s};
  background: ${defaultTheme.colors.lightGrey};

`;

export const PrivacyContentContainer = styled.div`
  display: block;
  min-height: 100vh;
  width: 60%;
  padding: ${defaultTheme.spacing.xs};
  margin-left: 20%;

  li {
    font-family: Montserrat;
  }
  
  ol {
    margin-left: -40px;
  }

  a {
    font-family: Montserrat;
    color: ${defaultTheme.colors.primary};
  }
`;