import * as React from "react";
import Metadata from "../components/layout/MetaData";
import { GlobalStyles } from "../styles/GlobalStyles";
import { PrivacyPolicy } from "../components/sections/PrivacyPolicy/PrivacyPolicy";
import { useIntl } from "gatsby-plugin-react-intl";

const PrivacyPolicyPage = () => {
    const intl = useIntl();
    const name = intl.locale === "pl" ? "Polityka prywatności" : "Privacy Policy";

    return (
        <>
            <GlobalStyles />
            <Metadata title={name} />
            <PrivacyPolicy />
        </>
    );
};

export default PrivacyPolicyPage;
